<template>
  <v-overlay
    :value="show"
  >
    <v-card
      width="500"
      light
    >
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Пересчет стоимости
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        Вы уверены, что хотите пересчитать стоимость товаров?
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5CB7B1"
          dark
          @click="submit"
        >
          Пересчитать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'

export default {
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
  },
  methods:{
    submit(){
      this.$emit('perform', {type:'invoice_cost'})
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
